/**
 * @typedef {Object} Position
 * Conforms to https://jsonresume.org/schema/
 *
 * @property {string} name - Name of the company
 * @property {string} position - Position title
 * @property {string} url - Company website
 * @property {string} startDate - Start date of the position in YYYY-MM-DD format
 * @property {string|undefined} endDate - End date of the position in YYYY-MM-DD format.
 * If undefined, the position is still active.
 * @property {string|undefined} summary - html/markdown summary of the position
 * @property {string[]} highlights - plain text highlights of the position (bulleted list)
 */
const experience = [
  {
    name: 'Canada Revenue Agency',
    position: 'Software Developer II',
    url: 'https://www.canada.ca/en/revenue-agency.html',
    startDate: '2024-01-01',
    endDate: 'Present',
    highlights: [
      'Developed a Java application designed to standardize server management, dynamically scraping any server in config file, provisioning new servers via REST APIs, and running 100+ integration tests successfully.',
      'Implemented a logging system that identifies frequent error patterns and embeds resolution steps directly in terminal, enhancing issue traceability and self-troubleshooting, resulting in a 30% reduction in support tickets.',
      'Ensured optimal system functionality via code review, software maintenance, testing and providing active support.',
      'Automated dynamic schema fetch logic for remote databases by creating scripts to retrieve data rows and generate SQL for tables, schemas, sequences, and indexes, resulting in a 90% reduction in manual updates',
      'Migrated a widely used legacy refresh tool to Java by using advance design patterns, improving system stability, adding user authorization to ensure secure access.',
      'Engineered a priority-based application deployment mechanism, streamlining update processes.',
      'Developed, and maintained Java Maven plugins used by internal teams to assist in build processes.',
    ],
    projects: [],
  },
  {
    name: 'Toddle - Your Teaching Partner',
    position: 'Software Engineer',
    url: 'https://www.toddleapp.com',
    startDate: '2022-07-01',
    endDate: '2022-11-30',
    summary: `Toddle is an EdTech SaaS company that offers a unified platform for teachers to collaborate and track learning outcomes. 
      Currently, Toddle is used by teachers at 1,500+ IB and other progressive schools from 100+ countries. Recently, Toddle raised $17M in series in Series A funding.<br>`,
    highlights: [
      'Independently designed and executed the development of both frontend and backend components for the Platform Manager feature, helped managing access of 250+ internal team members and eliminated manual work completely.',
      'Conducted research on various techniques for horizontal scaling of databases and implemented an efficient solution that resulted in a performance improvement and cost reduction of the system.',
      'Mentored 4 new graduates by providing codebase walkthroughs, system understanding, and aiding in debugging.',
      'Explored multiple approaches to securely store codebase secrets and successfully integrated AWS Secrets Manager into different repositories, ensuring a highly secure system.',
      'Tools/Tech: AWS Lambda, Secrets Manager, IAM, CloudWatch, ReactJS, NodeJS, GraphQL, PostgreSQL',
    ],
    projects: [
      {
        id: 1,
        title: '1. Multiregion Infra',
        description: [
          'Worked in a team to develop and implement a distributed system that ensured user data remained localized within their respective regions, resulting in improved security and data protection.',
          'Contributed to the enhancement and rewriting of backend code by implementing crucial modifications such as integrating replicated and core tables for authentication, ensuring seamless data migration, and redesigning APIs for user management, authentication, and data manipulation. ',
          'Restructured inter-region authentication routing in the frontend, formulated a comprehensive test plan, and implemented pivotal logic changes in critical code sections. These proactive measures yielded business expansion and revenue for Toddle.',
        ],
      },
      {
        id: 2,
        title: '2. Horizontal Scaling Database',
        description: [
          'Implemented master-slave architecture to horizontally scale the database, reducing vertical scaling costs and improving response time.',
          'Researched and proposed various approaches at different levels including frontend, backend, Knex, and PG Pool. Implemented logic to ensure a smooth transition while incrementally rolling out the slave database.',
          'Developed a comprehensive understanding of fundamental system design terminologies.',
        ],
      },
      {
        id: 3,
        title: '3. Managing Codebase Secrets',
        description: [
          'Collaborated with the team to explore different approaches, including exposing secrets through CI/CD, serverless architectures, and webpack.',
          'This project provided invaluable insights into critical facets of the codebase, including CI/CD processes, serverless architectures, and webpack implementation.',
        ],
      },
      {
        id: 4,
        title: '4. Platform Access Manager',
        description: [
          'Independently created the Access Manager feature, handling both frontend and backend development, which automated manual and repetitive tasks.',
          'This feature streamlined access management for internal team members on the Toddle platform, enabling customized views based on permissions that were managed through the feature',
          'Actively worked on feedback throughout the development process.',
        ],
      },
      {
        id: 5,
        title: '5. Node Version Upgrade',
        description: [
          'Upgraded backend services from Node.js runtime v12 to v16, optimizing code execution speed and reducing memory usage.',
          'Conducted thorough testing of all backend services using sample APIs to verify the integrity of existing code and functionality.',
        ],
      },
      {
        id: 6,
        title: '6. Pre-prod setup',
        description: [
          'Refactored the backend code to enhance scalability and facilitate the addition of new environments.',
          'Added a pre-production stage in CI/CD which helped with the final validation of upgrades, fixes, test data migration, and porting.',
        ],
      },
      {
        id: 7,
        title: '7. Score Assessment',
        description: [
          'Integrated a seamless grading feature for teachers, enabling them to assess online classroom tasks submitted by students',
          'Customizable categories were incorporated to cater to specific administrative needs.',
          'The system provided students and family members with access to their scores, while empowering teachers with class insights through interactive charts.',
        ],
      },
    ],
  },
  {
    name: 'Toddle - Your Teaching Partner',
    position: 'Associate Software Engineer',
    url: 'https://www.toddleapp.com',
    startDate: '2021-07-01',
    endDate: '2022-06-30',
    highlights: [
      'Collaborated and Re-engineered a robust user authentication to make system distributed by introducing several PostgreSQL tables and implemented essential changes in 20+ APIs in core backend codebase.',
      'Work in a team of 3, and collaborated with other teams to transform SaaS product into a multi-region offering, supporting multiple data centers, that led to revenue growth for the organization.',
      'Contributed to the setup of the pre-production environment, resulting in a streamlined code release flow.',
      'Integrated a seamless grading feature into the toddle’s classroom platform, allowing teachers to assess tasks submitted by students. Implemented features include interactive charts for class insights, customizable categories for administrators, and the ability to display grades in progress reports.',
      'Promptly addressed and resolved user-facing critical issues and bugs in both frontend and backend.',
      'Tools/Tech: JavaScript, NodeJS, ExpressJS, PostgreSQL, ReactJS, ChartJS, Scripting, Sentry, CI-CD',
    ],
    projects: [],
  },
  {
    name: 'Toddle - Your Teaching Partner',
    position: 'Software Engineer Intern',
    url: 'https://www.toddleapp.com',
    startDate: '2021-01-01',
    endDate: '2021-06-30',
    highlights: [
      'Single-handedly developed a Family Web Portal from scratch, creating over 25 new React components and implementing numerous changes in the existing frontend codebase. Achieved a timely and bug-free delivery.',
      'Developed an interactive frontend feature that effectively communicates the latest product updates to users, incorporating Like, Dislike, Share, and Feedback functionality to enhance user engagement. Utilized Mix panel to track analytics and gain insights into user interaction patterns.',
      'Collaborated effectively with Product Managers, Designers, Senior Engineers to deliver features.',
      'Created a Cumulative view for students where they can access upcoming deadlines through Homepage widget.',
      'Tools/Tech: ReactJS, React Native, SCSS, Webpack, GraphQL, Apollo Client, Directus, MixPanel',
    ],
    projects: [],
  },
];

export default experience;
